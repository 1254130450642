import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../../components/layout"
import ChainInfo from "../../../../views/chain-info"
import { Seo } from "../../../../components/seo"
import {
  exportToCsv,
  getBanner,
  reduceValue,
} from "../../../../components/utils"
import axios from "axios"
// import * as mainStyles from "../../../../styles/index.module.scss"
import Spinner from "../../../../components/spinner"
import {
  toDate,
  parseISO,
  differenceInMilliseconds,
  isAfter,
  getTime,
} from "date-fns"
import Code from "../../../../components/Code"
import { BsDownload } from "react-icons/bs"

export const Head = ({
  location,
  data: { testChainsJson: chain, allFile: files },
}) => (
  <Seo
    title={`${chain.name} Testnet account balances`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
)

const defaultRpc = "https://uptick-testnet-archive-rpc.brocha.in"
const defaultRest = "https://uptick-testnet-archive-rest.brocha.in"
const defaultExplorer = "https://testnet-explorer.brocha.in/uptick"

const Chain = props => {
  const { testChainsJson, allFile } = props.data
  const [accountAddress, setAccountAddress] = React.useState("")
  const [accounts, setAccounts] = React.useState([])
  const [accountBalances, setAccountBalances] = React.useState({})
  const [filter, setFilter] = React.useState("")

  const [height, setHeight] = React.useState(1)
  const [statusMessage, setStatusMessage] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [transactions, setTransactions] = React.useState([])
  const [rpcAddress, setRpcAddress] = React.useState(defaultRpc)
  const [restAddress, setRestAddress] = React.useState(defaultRest)
  const [explorerAddress, setExplorerAddress] = React.useState(defaultExplorer)

  const getTransactionsBackToHeight = async (address, height) => {
    var txs = []
    // TODO: iterate over pages
    setStatusMessage(`Checking transactions for ${address}...`)
    const txsResponse = await fetch(
      `${restAddress}/cosmos/tx/v1beta1/txs?events=coin_spent.spender%3D%27${address}%27&coin_received.receiver%3D%27${address}%27"`
    )
    const txsJson = await txsResponse.json()
    for (let i = 0; i < txsJson.tx_responses.length; i++) {
      const tx_response = txsJson.tx_responses[i]
      if (Number(tx_response.height) < height) {
        // console.log(`Skipping txs as height ${Number(tx_response.height)} < ${height}`);
        continue
      }
      for (let j = 0; j < tx_response.tx.body.messages.length; j++) {
        const message = tx_response.tx.body.messages[j]
        if (message.from_address == address || message.to_address == address) {
          txs.push({
            message: message,
            fees: tx_response.tx.auth_info.fee.amount,
            height: tx_response.height,
            hash: tx_response.txhash,
            isSender: true, // TODO: Check if this is the case
          })
        }
      }
    }
    return txs
  }

  const calculateTotalBalance = account => {
    let resultingBalances = JSON.parse(JSON.stringify(account.currentBalances))
    for (let i = 0; i < account.transactions.length; i++) {
      if (account.transactions[i].message.from_address === account.address) {
        for (const amount of account.transactions[i].message.amount) {
          // console.log(`Sent ${amount.amount} ${amount.denom}`);
          resultingBalances.filter(b => b.denom === amount.denom)[0].amount = (
            Number(
              resultingBalances.filter(b => b.denom === amount.denom)[0].amount
            ) + Number(amount.amount)
          ).toLocaleString("fullwide", { useGrouping: false })
        }
      } else if (
        account.transactions[i].message.to_address === account.address
      ) {
        for (const amount of account.transactions[i].message.amount) {
          // console.log(`Received ${amount.amount} ${amount.denom}`);
          resultingBalances.filter(b => b.denom === amount.denom)[0].amount = (
            Number(
              resultingBalances.filter(b => b.denom === amount.denom)[0].amount
            ) - Number(amount.amount)
          ).toLocaleString("fullwide", { useGrouping: false })
        }
      } else if (
        account.transactions[i].message.delegator_address === account.address
      ) {
        // this is ok, ignore it
      } else {
        console.error(`Something is wrong here!`)
      }

      if (
        account.transactions[i].isSender &&
        account.transactions[i].fees !== undefined
      ) {
        for (const fee of account.transactions[i].fees) {
          // console.log(`Paid ${fee.amount} ${fee.denom} in fees`);
          resultingBalances.filter(b => b.denom === fee.denom)[0].amount = (
            Number(
              resultingBalances.filter(b => b.denom === fee.denom)[0].amount
            ) + Number(fee.amount)
          ).toLocaleString("fullwide", { useGrouping: false })
        }
      }
    }

    return resultingBalances
  }

  const getStaking = async account => {
    const delegations = await fetch(
      `${restAddress}/cosmos/staking/v1beta1/delegations/${account.address}`
    )
    const delegationsJson = await delegations.json()

    if (delegationsJson.delegation_responses.length === 0) {
      return []
    }
    let stakesNow = []
    for (const delegation of delegationsJson.delegation_responses) {
      if (
        stakesNow.filter(t => t.denom === delegation.balance.denom).length === 0
      ) {
        stakesNow.push({
          denom: delegation.balance.denom,
          amount: Number(delegation.balance.amount),
        })
      } else {
        stakesNow.filter(t => t.denom === delegation.balance.denom)[0].amount +=
          Number(delegation.balance.amount)
      }
    }
    for (const transaction of account.transactions) {
      if (
        stakesNow.filter(t => t.denom === transaction.message.amount.denom)
          .length === 0
      ) {
        stakesNow.push({
          denom: transaction.message.amount.denom,
          amount: Number(0),
        })
      }
      if (
        transaction.message["@type"] === "/cosmos.staking.v1beta1.MsgDelegate"
      ) {
        // console.log( `Adjusting stakes by -${transaction.message.amount.amount}`)
        stakesNow.filter(
          t => t.denom === transaction.message.amount.denom
        )[0].amount -= Number(transaction.message.amount.amount)
        // stakesNow -= Number(transaction.message.amount.amount);
      }
      if (
        transaction.message["@type"] === "/cosmos.staking.v1beta1.MsgUndelegate"
      ) {
        // console.log( `Adjusting stakes by ${transaction.message.amount.amount}`)
        stakesNow.filter(
          t => t.denom === transaction.message.amount.denom
        )[0].amount += Number(transaction.message.amount.amount)
        // stakesNow += Number(transaction.message.amount.amount);
      }
    }

    // console.log( `Total stakes after adjusting for transactions for ${account.address}: ${stakesNow}` );
    return stakesNow
  }

  const doLookup = async () => {
    setIsLoading(true)
    setStatusMessage("")
    setAccountBalances({})

    try {
      const statusResponse = await fetch(`${rpcAddress}/status`)
      const statusJson = await statusResponse.json()
      const earliestHeight = parseInt(
        statusJson.result.sync_info.earliest_block_height
      )
      const latestHeight = parseInt(
        statusJson.result.sync_info.latest_block_height
      )

      if (height < earliestHeight) {
        setStatusMessage(
          `ERROR: The "from height" is not available on the node. ${height} is lower than ${earliestHeight}.`
        )
        return
      } else if (height > latestHeight) {
        setStatusMessage(
          `ERROR: The "to height" is not available on the node. ${height} is higher than ${latestHeight}.`
        )
        return
      }
      const blockResponse = await fetch(`${rpcAddress}/block?height=${height}`)
      const blockJson = await blockResponse.json()
      const timeAtBlock = parseISO(blockJson.result.block.header.time)

      let page = 0
      const allCurrentAccountsResponse = await fetch(
        `${restAddress}/cosmos/auth/v1beta1/accounts?pagination.offset=${page}&pagination.limit=100`
      )
      const allCurrentAccountsJson = await allCurrentAccountsResponse.json()

      setAccounts(
        allCurrentAccountsJson.accounts.map(account =>
          account.base_vesting_account
            ? account.base_vesting_account.base_account.address
            : account.base_account.address
        )
      )

      const accountBalancesTmp = {}

      const filteredBalances = allCurrentAccountsJson.accounts.filter(
        a =>
          a["@type"] !== "/cosmos.auth.v1beta1.ModuleAccount" &&
          (!accountAddress ||
            accountAddress ===
              (a.base_account ?? a.base_vesting_account.base_account).address)
      )

      for (let a of filteredBalances) {
        const address = (a.base_account ?? a.base_vesting_account.base_account)
          .address
        setStatusMessage(`Checking account ${address}...`)
        const balancesResponse = await fetch(
          `${restAddress}/cosmos/bank/v1beta1/balances/${address}`
        )
        let balancesJson = await balancesResponse.json()
        let account = {
          address: address,
          type: a["@type"],
          currentBalances: balancesJson.balances,
          atBlockBalances: [],
          atBlockStaked: [],
          transactions: await getTransactionsBackToHeight(address, height),
          vesting: {
            isVestingAccount: false,
            originalVesting: [],
            unlockedSoFar: [],
            stillLocked: [],
          },
        }

        if (a["@type"] === "/cosmos.vesting.v1beta1.ContinuousVestingAccount") {
          account.vesting.isVestingAccount = true
          account.vesting.originalVesting =
            a.base_vesting_account.original_vesting

          const startTime = toDate(Number(`${a.start_time}000`))
          const endTime = toDate(
            Number(`${a.base_vesting_account.end_time}000`)
          )
          if (isAfter(timeAtBlock, endTime)) {
            account.vesting.unlockedSoFar = account.vesting.originalVesting
            account.vesting.stillLocked = []
          } else if (isAfter(timeAtBlock, startTime)) {
            const vestingPeriod = differenceInMilliseconds(endTime, startTime)
            // console.log(`Vesting period: ${vestingPeriod}ms`)
            const timeSinceStart = differenceInMilliseconds(
              timeAtBlock,
              startTime
            )
            // console.log(`Time since start: ${timeSinceStart}ms`)
            const percentageUnlocked = timeSinceStart / vestingPeriod
            // console.log(`Percentage unlocked: ${percentageUnlocked}`);

            for (const vest of account.vesting.originalVesting) {
              account.vesting.unlockedSoFar.push({
                amount: (
                  Number(vest.amount) * percentageUnlocked
                ).toLocaleString("fullwide", { useGrouping: false }),
                denom: vest.denom,
              })
              account.vesting.stillLocked.push({
                amount: (
                  Number(vest.amount) *
                  (1 - percentageUnlocked)
                ).toLocaleString("fullwide", { useGrouping: false }),
                denom: vest.denom,
              })
            }
          }
        } else if (
          a["@type"] === "/cosmos.vesting.v1beta1.DelayedVestingAccount"
        ) {
          account.vesting.isVestingAccount = true
          account.vesting.originalVesting =
            a.base_vesting_account.original_vesting

          const endTime = toDate(
            Number(`${a.base_vesting_account.end_time}000`)
          )
          if (endTime < timeAtBlock) {
            account.vesting.unlockedSoFar = account.vesting.originalVesting
            account.vesting.stillLocked = []
          } else {
            account.vesting.unlockedSoFar = []
            account.vesting.stillLocked = account.vesting.originalVesting
          }
        } else if (
          a["@type"] === "/cosmos.vesting.v1beta1.PeriodicVestingAccount"
        ) {
          account.vesting.isVestingAccount = true
          account.vesting.originalVesting =
            a.base_vesting_account.original_vesting

          const startTime = toDate(Number(`${a.start_time}000`))
          const endTime = toDate(
            Number(`${a.base_vesting_account.end_time}000`)
          )
          if (isAfter(timeAtBlock, endTime)) {
            account.vesting.unlockedSoFar = account.vesting.originalVesting
            account.vesting.stillLocked = []
          } else if (isAfter(timeAtBlock, startTime)) {
            for (const period of a.vesting_periods) {
              const unlockAt = toDate(
                getTime(startTime) + Number(`${period.length}000`)
              )
              if (unlockAt < timeAtBlock) {
                for (const amount of period.amount) {
                  if (
                    account.vesting.unlockedSoFar.filter(
                      a => a.denom === amount.denom
                    ).length === 0
                  ) {
                    account.vesting.unlockedSoFar.push(amount)
                  } else {
                    account.vesting.unlockedSoFar.filter(
                      a => a.denom === amount.denom
                    )[0].amount = (
                      Number(
                        account.vesting.unlockedSoFar.filter(
                          a => a.denom === amount.denom
                        )[0].amount
                      ) + Number(amount.amount)
                    ).toLocaleString("fullwide", { useGrouping: false })
                  }
                }
              } else {
                for (const amount of period.amount) {
                  if (
                    account.vesting.stillLocked.filter(
                      a => a.denom === amount.denom
                    ).length === 0
                  ) {
                    account.vesting.stillLocked.push(amount)
                  } else {
                    account.vesting.stillLocked.filter(
                      a => a.denom === amount.denom
                    )[0].amount = (
                      Number(
                        account.vesting.stillLocked.filter(
                          a => a.denom === amount.denom
                        )[0].amount
                      ) + Number(amount.amount)
                    ).toLocaleString("fullwide", { useGrouping: false })
                  }
                }
              }
            }
          }
        }
        account.atBlockBalances = calculateTotalBalance(account)
        account.atBlockStaked = await getStaking(account)
        console.log(account.atBlockStaked)
        accountBalancesTmp[account.address] = account
        setAccountBalances(accountBalancesTmp)
      }

      setAccountBalances(accountBalancesTmp)
      setStatusMessage("")
    } catch (e) {
      setStatusMessage(`ERROR: Something went wrong: "${e.message}"`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const downloadCsv = () => {
    const csvData = [
      [
        "Account",
        "Type",
        `Balances at ${height}`,
        `Staked at ${height}`,
        "Original vesting",
        "Still locked",
        "Unlocked so far",
      ],
      ...Object.values(accountBalances).map(a => [
        a.address,
        a.type
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .trim(),
        a.atBlockBalances
          ? a.atBlockBalances.map(a => `${a.amount}${a.denom}`).join(", ")
          : "",
        a.atBlockStaked.length
          ? a.atBlockStaked.map(a => `${a.amount}${a.denom}`).join(", ")
          : "",
        a.vesting.originalVesting
          ? a.vesting.originalVesting
              .map(a => `${a.amount}${a.denom}`)
              .join(", ")
          : "",
        a.vesting.stillLocked
          ? a.vesting.stillLocked.map(a => `${a.amount}${a.denom}`).join(", ")
          : "",
        a.vesting.unlockedSoFar.length
          ? a.vesting.unlockedSoFar.map(a => `${a.amount}${a.denom}`).join(", ")
          : "",
      ]),
    ]
    exportToCsv("balances.csv", csvData)
  }

  return (
    <Layout>
      <ChainInfo chain={testChainsJson} data={allFile.edges} testnet />
      <h2>Account balances</h2>
      <h3>Uptick Testnet</h3>
      <label htmlFor="accountAddress">Account address</label>
      <input
        id="accountAddress"
        type="text"
        value={accountAddress}
        onChange={e => setAccountAddress(e.target.value)}
        placeholder="uptick1ncn0k65x3esuzxztzymd0s0kwhun7wxnrcc9mw"
        style={{ width: "calc(100% - 2rem)" }}
        disabled={isLoading}
      />
      <label htmlFor="block">Block height</label>
      <input
        id="block"
        type="number"
        value={height}
        onChange={e => setHeight(e.target.value)}
        placeholder="123456"
        style={{ width: "calc(100% - 2rem)" }}
        disabled={isLoading}
      />
      <button onClick={doLookup}>Search</button>
      <fieldset>
        <legend>Advanced</legend>
        <label htmlFor="rpcAddress">RPC address</label>
        <input
          id="rpcAddress"
          type="text"
          value={rpcAddress}
          onChange={e => setRpcAddress(e.target.value)}
          placeholder="https://uptick-testnet-3-rpc.brocha.in"
          style={{ width: "calc(100% - 2rem)" }}
          disabled={isLoading}
        />
        <label htmlFor="restAddress">API address</label>
        <input
          id="restAddress"
          type="text"
          value={restAddress}
          onChange={e => setRestAddress(e.target.value)}
          placeholder="https://uptick-testnet-3-rest.brocha.in"
          style={{ width: "calc(100% - 2rem)" }}
          disabled={isLoading}
        />
        <em>
          Note: Changing the RPC and API address' to a different network
          requires you to change the explorer link accordingly. Otherwise the
          links will not work.
        </em>
        <label htmlFor="explorerAddress">Explorer address</label>
        <input
          id="explorerAddress"
          type="text"
          value={explorerAddress}
          onChange={e => setExplorerAddress(e.target.value)}
          placeholder="https://testnet-explorer.brocha.in/uptick%20phase%203"
          style={{ width: "calc(100% - 2rem)" }}
        />
      </fieldset>
      {statusMessage ? <Code>{statusMessage}</Code> : null}
      {accounts.length ? (
        <React.Fragment>
          <table cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Account</th>
                <th>Balances</th>
              </tr>
            </thead>
            <tbody>
              {accounts.length ? (
                accounts
                  .filter(
                    account => !accountAddress || accountAddress === account
                  )
                  .map((account, i) => (
                    <tr key={i}>
                      <td>
                        <a
                          href={`${explorerAddress}/account/${account}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <abbr title={account}>
                            {account.substr(0, 9)}...{account.substr(-5)}
                          </abbr>
                        </a>
                      </td>
                      <td>
                        {accountBalances[account] ? (
                          <React.Fragment>
                            Liquid balance:{" "}
                            {accountBalances[account].atBlockBalances
                              .filter(tokens => tokens.amount)
                              .map(
                                tokens =>
                                  `${reduceValue(
                                    tokens.amount,
                                    tokens.denom
                                  )} ${tokens.denom.toUpperCase().substr(1)}`
                              )
                              .join(", ")}
                            <br />
                            Staked balance:{" "}
                            {accountBalances[account].atBlockStaked
                              .filter(tokens => tokens.amount)
                              .map(
                                tokens =>
                                  `${reduceValue(
                                    tokens.amount,
                                    tokens.denom
                                  )} ${tokens.denom.toUpperCase().substr(1)}`
                              )
                              .join(", ") || 0}
                            <br />
                            Account total:{" "}
                            {accountBalances[account].atBlockBalances
                              .filter(tokens => tokens.amount)
                              .map(tokens => {
                                const staked = accountBalances[
                                  account
                                ].atBlockStaked.filter(tokens => tokens.amount)
                                const stakedAmount =
                                  staked.length > 0
                                    ? Number(staked[0].amount)
                                    : 0
                                // console.log( `StaledAmount: ${stakedAmount}`);
                                return `${reduceValue(
                                  Number(tokens.amount) + stakedAmount,
                                  tokens.denom
                                )} ${tokens.denom.toUpperCase().substr(1)}`
                              })
                              .join(", ")}
                            {accountBalances[account].vesting
                              .isVestingAccount ? (
                              <React.Fragment>
                                <h4>Vesting</h4>
                                Original vesting:{" "}
                                {accountBalances[
                                  account
                                ].vesting.originalVesting
                                  .filter(tokens => tokens.amount)
                                  .map(
                                    tokens =>
                                      `${reduceValue(
                                        tokens.amount,
                                        tokens.denom
                                      )} ${tokens.denom
                                        .toUpperCase()
                                        .substr(1)}`
                                  )
                                  .join(", ") || 0}
                                <br />
                                Still locked:{" "}
                                {accountBalances[account].vesting.stillLocked
                                  .filter(tokens => tokens.amount)
                                  .map(
                                    tokens =>
                                      `${reduceValue(
                                        tokens.amount,
                                        tokens.denom
                                      )} ${tokens.denom
                                        .toUpperCase()
                                        .substr(1)}`
                                  )
                                  .join(", ") || 0}
                                <br />
                                Unlocked so far:{" "}
                                {accountBalances[account].vesting.unlockedSoFar
                                  .filter(tokens => tokens.amount)
                                  .map(
                                    tokens =>
                                      `${reduceValue(
                                        tokens.amount,
                                        tokens.denom
                                      )} ${tokens.denom
                                        .toUpperCase()
                                        .substr(1)}`
                                  )
                                  .join(", ") || 0}
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        ) : isLoading ? (
                          <Spinner />
                        ) : (
                          <em>N/A</em>
                        )}
                      </td>
                    </tr>
                  ))
              ) : isLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Spinner /> Loading account balances...
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <button onClick={downloadCsv} disabled={isLoading}>
            <BsDownload /> Download results (.csv)
          </button>
        </React.Fragment>
      ) : null}
    </Layout>
  )
}

export default Chain

export const query = graphql`
  query {
    testChainsJson(key: { eq: "uptick" }) {
      id
      about
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
